<template>
    <main>
        <section class="container">
            <div class="row">
                <div class="col-lg-6 g-mb-30">
                    <header class="u-heading-v8-2 g-mb-60 g-mt-60">
                        <h2 class="u-heading-v8__title g-font-weight-600 g-color-gray-dark-v3 g-mb-25 font-color-title">Contáctanos</h2>
                        <p class="mb-0 g-mr-40 font-color-subtitle" style="text-align: justify;">La Asociación de Bancos e Instituciones Financieras (ABIF) ha establecido un sistema de resolución de conflictos mediante el cual los clientes de los bancos adscritos cuentan con una instancia independiente donde sus reclamos pueden
                            ser resueltos.</p>

                        <!-- <ul class="list-inline mb-0 g-mt-30">
                            <a class="u-icon-v3 g-bg-gray-dark-v5 g-rounded-50x g-color-white g-color-white--hover g-mr-15 g-mb-20" href="#">
                                <i class="fa fa-facebook"></i>
                            </a>
                            <a class="u-icon-v3 g-bg-gray-dark-v5 g-rounded-50x g-color-white g-color-white--hover g-mr-15 g-mb-20" href="#">
                                <i class="fa fa-linkedin"></i>
                            </a>
                            <a class="u-icon-v3 g-bg-gray-dark-v5 g-rounded-50x g-color-white g-color-white--hover g-mr-15 g-mb-20" href="#">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </ul> -->
                    </header>
                </div>

                <div class="col-lg-6 g-bg-gray-light-v4 g-py-90">
                    <div class="mx-auto g-width-70x--md">
                        <form>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 font-color-subtitlename ">
                                    <p><img src="assets/img/name.svg"> Ingresa tu nombre</p>
                                    <div class="form-group g-mb-1">
                                        <div class="u-input-group-v2">
                                            <input id="name" class="form-control rounded-3 u-form-control" name="name" type="text">
                                            <label for="name">Ingresa tu nombre</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 font-color-subtitlename ">
                                    <p><img src="assets/img/mail.svg"> Ingresa tu email</p>
                                    <div class="form-group g-mb-1">
                                        <div class="u-input-group-v2">
                                            <input id="mail" class="form-control rounded-3 u-form-control" name="name" type="mail">
                                            <label for="mail">usuario@email.com</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 font-color-subtitlename ">
                                    <div class="form-group g-mb-1">
                                        <label class="g-mb-10" for="inputGroup2_1"><img src="assets/img/icon-msg.svg">  Mensaje</label>
                                        <textarea id="inputGroup2_1" class="form-control form-control-md g-resize-none rounded-3" rows="3" placeholder="¿Cuéntanos en qué podemos ayudarte?"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="text-md-right">
                                <button class="g-font-weight-600 btn btn-lg u-btn-blue g-mr-10 g-mt-10 g-mb-15 font-color-subtitlename " type="submit" role="button">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    export default {
        beforeMount () {
            window.scrollTo(0, 0);
        },
    }
</script>

<style lang="scss">
  .font-color-name {
    color: #555;
    font-size:17.5px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .font-color-subtitlename {
    font-weight: 400;
    font-size: 16px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #555;
  }
    .font-color-subtitle {
    font-weight: 400;
    font-size: 1rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #555 !important;
  }
    .font-color-title {
    font-weight: 600;
     font-size: 2rem !important;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #555 !important;
  }
  .u-btn-blue {
    color: #fff;
    background-color: #199BCA;
    width: 180px;
}
  </style>
